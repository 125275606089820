import React, { useEffect, useState } from "react"
import Layout from "../components/Layout/Layout"
import SEO from "../components/Core/Seo"
import { graphql } from "gatsby"
import styled from "styled-components"
import { Col } from "styled-bootstrap-grid"
import fonts from "../styles/fonts"
import breakpoints from "../styles/breakpoints"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { MARKS } from "@contentful/rich-text-types"
import CoreContainer from "../components/Core/CoreContainer"
import colors from "../styles/colors"

const options = {
  renderMark: {
    [MARKS.BOLD]: (text) => <Bold>{text}</Bold>,
  },
  renderText: (text) => {
    return text.split("\n").reduce((children, textSegment, index) => {
      return [...children, index > 0 && <br key={index} />, textSegment]
    }, [])
  },
}


const Bold = styled.span`
  ${fonts.nhassGroteskRoman};
  font-weight: 700;
`

const ContainerStyled = styled.div`
  display: flex;
  flex-wrap: wrap;
  min-height: 100vh;
  margin-top: -80px;
  
  @media (min-width: ${breakpoints.md}) {
    height: auto;
    padding-left: 100px;
    align-items: center;
  }
  
  @media (min-width: ${breakpoints.lg}) {
    margin-top: -120px;
  }
  
  @media (min-width: ${breakpoints.xl}) {
    padding-left: 168px;
  }
  
  @media (min-width: 2560px) {
    padding-left: 302px
  }
`

const Text = styled.div`
  font-size: 12.48px;
  letter-spacing: 0.6px;
  line-height: 21.216px;
  padding: 224px 10px 16px;
  
  p {
    line-height: 20px;
  }

  a {
    text-decoration: none;
    color: ${colors.black};
    letter-spacing: 0.6px;
  }

  @media (min-width: ${breakpoints.md}) {
    padding: 0px;
    font-size: 9.23px;
    line-height: 16px;
    width: 295px;
    letter-spacing: 0.6px;
    
    p {
      line-height: 16px;
    }
  }
`

const About = (props) => {
  const path = props.location.pathname
  const page = props.data.page
  const { text } = page

  return (
    <Layout>
      <SEO
        title={`${page.metaTitle}`}
        description={page.metaDescription}
        fullTitle={false}
        path={path}
      />
      <CoreContainer>
        <ContainerStyled>
          <Col lg={4}>
            <Text>{documentToReactComponents(text.json, options)}</Text>
          </Col>
        </ContainerStyled>
      </CoreContainer>
    </Layout>
  )
}

export default About

export const pageQuery = graphql`
  query AboutQuery {
    page: contentfulAbout(slug: { eq: "about" }) {
      id
      metaTitle
      metaDescription
      text {
        json
      }
    }
  }
`
